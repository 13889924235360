@import '@hiredigital/ui/variables';

.heroTitle {
  color: $white;
  letter-spacing: -1px;
  font-size: $size-9;
  line-height: $line-height-9;
  font-weight: $fw-1;
  margin-bottom: 24px;

  @include for-size(tablet-landscape-down) {
    font-size: 40px;
    line-height: 48px;
  }

  @include for-size(phone-only) {
    font-size: $size-8;
    line-height: $line-height-8;
  }
}

.heroMainDes {
  max-width: 760px;
  margin-bottom: 30px;
  font-size: $size-5;
  line-height: $line-height-5;

  @include for-size(phone-only) {
    font-size: $size-3;
    line-height: $line-height-3;
    text-align: left;
  }
}

.container {
  max-width: $content-width + 40px;
  width: 100%;
  padding: 150px 20px 90px;
  margin: 0 auto;
  display: flex;
  color: $white;
  box-sizing: border-box;
  z-index: $z-base;
  overflow: hidden;

  @include for-size(desktop-down) {
    padding: 150px 20px 60px;
  }

  @include for-size(phone-only) {
    flex-wrap: wrap;

    padding: 100px 20px 30px;
  }
}

.mainHeroCta {
  display: flex;
  @include for-size(phone-only) {
    flex-direction: column;
    .btnCta {
      margin: 5px 0;
    }
  }
}

.btnCta {
  // IE11 Fix
  flex-shrink: 0;
  // min-width: 200px !important;
}

@import '@hiredigital/ui/variables';

.card {
  &:hover .title,
  & .title:hover {
    text-decoration: underline;
  }
}

.image {
  height: 75px;
  width: 75px;
}
